import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "User manual";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AspectRatio = makeShortcode("AspectRatio");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "user-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#user-manual",
        "aria-label": "user manual permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`User manual`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#what-is-armeria"
          }}>{`What is Armeria?`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#want-a-quick-tour"
          }}>{`Want a quick tour?`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#features"
          }}>{`Features`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#http2"
            }}>{`HTTP/2`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#integration-with-grpc-and-thrift"
            }}>{`Integration with gRPC and Thrift`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#essential-features-for-building-microservices"
            }}>{`Essential features for building microservices`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#interactive-web-based-debug-console"
            }}>{`Interactive web-based debug console`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#completely-asynchronous-and-reactive"
            }}>{`Completely asynchronous and reactive`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#compatibility-with-existing-java-ee-web-applications"
            }}>{`Compatibility with existing Java EE web applications`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#even-higher-performance-on-linux"
            }}>{`Even higher performance on Linux`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "what-is-armeria",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-is-armeria",
        "aria-label": "what is armeria permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is Armeria?`}</h2>
    <p><em parentName="p">{`Armeria`}</em>{` is your go-to microservice framework for any situation. You can build any type of
microservice leveraging your favorite technologies, including `}<a parentName="p" {...{
        "href": "https://grpc.io/"
      }}>{`gRPC`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://thrift.apache.org/"
      }}>{`Thrift`}</a>{`, `}<a parentName="p" {...{
        "href": "https://kotlinlang.org/"
      }}>{`Kotlin`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://square.github.io/retrofit/"
      }}>{`Retrofit`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.reactive-streams.org/"
      }}>{`Reactive Streams`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://spring.io/projects/spring-boot"
      }}>{`Spring Boot`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.dropwizard.io/"
      }}>{`Dropwizard`}</a>{`.`}</p>
    <p>{`It is open-sourced by the creator of `}<a parentName="p" {...{
        "href": "https://netty.io/"
      }}>{`Netty`}</a>{` and his colleagues at
`}<a parentName="p" {...{
        "href": "https://techblog.lycorp.co.jp/en"
      }}>{`LY Corporation`}</a>{`.`}</p>
    <h2 {...{
      "id": "want-a-quick-tour",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#want-a-quick-tour",
        "aria-label": "want a quick tour permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Want a quick tour?`}</h2>
    <p>{`Check out the recent introductory talk
(`}<a parentName="p" {...{
        "href": "https://speakerdeck.com/trustin/armeria-a-microservice-framework-well-suited-everywhere"
      }}>{`slides`}</a>{`):`}</p>
    <AspectRatio width={16} height={9} maxWidth="21rem" mdxType="AspectRatio">
  <iframe src="https://www.youtube.com/embed/Vr-0GKUmzo8" style={{
        "border": "none"
      }} allowFullScreen />
    </AspectRatio>
    <h2 {...{
      "id": "features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features",
        "aria-label": "features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features`}</h2>
    <h3 {...{
      "id": "http2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#http2",
        "aria-label": "http2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HTTP/2`}</h3>
    <ul>
      <li parentName="ul">{`Supports HTTP/2 on both TLS and cleartext connections`}</li>
      <li parentName="ul">{`Supports protocol upgrade via both HTTP/2 connection preface and traditional HTTP/1 upgrade request`}</li>
      <li parentName="ul">{`Fully compatible with existing HTTP/1 servers`}</li>
      <li parentName="ul">{`Integrated PROXY protocol support for interoperability with load balancers such as HAProxy and AWS ELB.`}</li>
    </ul>
    <h3 {...{
      "id": "integration-with-grpc-and-thrift",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#integration-with-grpc-and-thrift",
        "aria-label": "integration with grpc and thrift permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Integration with gRPC and Thrift`}</h3>
    <ul>
      <li parentName="ul">{`Your gRPC or Thrift service implementation runs on top of Armeria without any modification.`}</li>
      <li parentName="ul">{`Works with the official gRPC or Thrift-over-HTTP client`}</li>
      <li parentName="ul">{`Works with the code generated by the official Protobuf or Thrift IDL compiler`}</li>
      <li parentName="ul">{`Supports various protocol combinations, such as:`}
        <ul parentName="li">
          <li parentName="ul">{`gRPC-over-HTTP/1 & 2`}</li>
          <li parentName="ul">{`Thrift-over-HTTP/1 & 2`}</li>
          <li parentName="ul">{`gRPC-Web`}</li>
        </ul>
      </li>
      <li parentName="ul">{`See `}<a parentName="li" {...{
          "href": "/docs/server-thrift"
        }}>{`Running a Thrift service`}</a>{` and `}<a parentName="li" {...{
          "href": "/docs/server-grpc"
        }}>{`Running a gRPC service`}</a>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "essential-features-for-building-microservices",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#essential-features-for-building-microservices",
        "aria-label": "essential features for building microservices permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Essential features for building microservices`}</h3>
    <ul>
      <li parentName="ul">{`Metrics`}</li>
      <li parentName="ul">{`Circuit breaker`}</li>
      <li parentName="ul">{`Client-side health-check and load-balancing`}</li>
      <li parentName="ul">{`Service discovery from various sources such as DNS and ZooKeeper`}
        <ul parentName="li">
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "/docs/client-service-discovery"
            }}>{`Client-side load balancing and service discovery`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Distributed call tracing via Zipkin`}</li>
    </ul>
    <h3 {...{
      "id": "interactive-web-based-debug-console",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#interactive-web-based-debug-console",
        "aria-label": "interactive web based debug console permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Interactive web-based debug console`}</h3>
    <ul>
      <li parentName="ul">{`Browse the list of available RPC operations`}</li>
      <li parentName="ul">{`Invoke an RPC operation via a web form`}</li>
      <li parentName="ul">{`Share an RPC request with your colleagues so they can reproduce the problem easily`}
        <ul parentName="li">
          <li parentName="ul">{`Just like sharing a cURL command, but works for RPC`}</li>
        </ul>
      </li>
      <li parentName="ul">{`See `}<a parentName="li" {...{
          "href": "/docs/server-docservice"
        }}>{`Browsing and invoking services with DocService`}</a>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "completely-asynchronous-and-reactive",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#completely-asynchronous-and-reactive",
        "aria-label": "completely asynchronous and reactive permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Completely asynchronous and reactive`}</h3>
    <ul>
      <li parentName="ul">{`Built on top of Reactive Streams and Java 8 CompletableFuture`}</li>
      <li parentName="ul">{`Asynchronous connection pool ensures your application never blocks even on pool exhaustion.`}</li>
      <li parentName="ul">{`Domain name lookups are also fully asynchronous thanks to Netty’s asynchronous domain name resolver.`}</li>
    </ul>
    <h3 {...{
      "id": "compatibility-with-existing-java-ee-web-applications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#compatibility-with-existing-java-ee-web-applications",
        "aria-label": "compatibility with existing java ee web applications permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Compatibility with existing Java EE web applications`}</h3>
    <ul>
      <li parentName="ul">{`Runs any Java EE web applications such as Spring Boot on the same TCP/IP port`}</li>
      <li parentName="ul">{`Your Java EE web application speaks HTTP/2!`}</li>
      <li parentName="ul">{`See `}<a parentName="li" {...{
          "href": "/docs/server-servlet"
        }}>{`Embedding a servlet container`}</a>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "even-higher-performance-on-linux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#even-higher-performance-on-linux",
        "aria-label": "even higher performance on linux permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Even higher performance on Linux`}</h3>
    <ul>
      <li parentName="ul">{`JNI-based socket I/O`}</li>
      <li parentName="ul">{`BoringSSL-based TLS connections`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      